import { Typography } from '@mui/material'

import systemInstructions from '~/PDFs/WIN.Customer.Communication.Instructions.pdf'

export const dashboardFields = [
  {
    id: 'invoices',
    title: 'Open Invoices',
    cardSubTitle: (currentAccount, currentSubAccount) => (
      <>
        <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
          {currentAccount?.name}
        </Typography>
        {currentSubAccount && (
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {`${currentSubAccount.shipToNumber} - ${currentSubAccount.city} - ${currentSubAccount.state}`}
          </Typography>
        )}
      </>
    ),
    cardContent: [
      { label: 'Open', id: 'open_cnt' },
      { label: 'Past Due', id: 'past_due_cnt' },
    ],
  },
  {
    id: 'orders',
    title: 'Open Orders',
    cardSubTitle: (currentAccount, currentSubAccount) => (
      <>
        <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
          {currentAccount?.name}
        </Typography>
        {currentSubAccount && (
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {`${currentSubAccount.shipToNumber} - ${currentSubAccount.city} - ${currentSubAccount.state}`}
          </Typography>
        )}
      </>
    ),
    cardContent: [
      { label: 'Open', id: 'open_cnt' },
      { label: 'Picking', id: 'picking_cnt' },
      { label: 'Shipped', id: 'shipped_cnt' },
    ],
  },
]

export const dashboardButtons = [
  { name: 'System Instructions', href: systemInstructions },
  {
    name: 'Quarterly Market Report',
    href: 'https://amercareroyal.com/pages/market-updates',
  },
  {
    name: 'Product Catalog',
    href: 'https://amercareroyal.com/blogs/catalogs/amercareroyal-product-catalog',
  },
  // { name: 'Upcoming Price Change', href: undefined },
]

export const adminDashboardFields = [
  {
    id: 'userPending',
    title: 'Users Pending Approval',
    cardContent: [
      { label: 'Customer', id: 'customer_cnt' },
      { label: 'ACR Employee', id: 'employee_cnt' },
      { label: 'Broker', id: 'broker_cnt' },
      { label: 'Others', id: 'other_cnt' },
    ],
  },
]

export const adminDashboardButtons = [
  { name: 'System Instructions', href: systemInstructions },
  // { name: 'Upcoming Price Change', href: undefined },
]
